<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Games" subtitle="See all the games available in the library.">

		<app-content-head-actions-panel v-if="collection.length" text="Labels" icon="label" component="manager/common/panel/Sheet" :data="sheetData.badge" />
		<app-content-head-actions-panel v-if="collection.length" text="Export" icon="export" component="manager/common/panel/Export" :data="importexportData" />
		<app-content-head-actions-item :loading="is.syncing" text="Sync" icon="sync" v-on:click="onSyncClick" />
		<app-content-head-actions-item :divide="true" text="Add" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-select v-model="filter.collection" text="Any collection" title="Collection" tooltip="Filter by collection" :allowDeselect="true" :options="references.collections" v-if="references.collections.length" />
		<app-content-filters-select v-model="filter.sort" text="Sort by" :alwaysLabel="true" :alwaysValue="true" tooltip="Change sorting" :options="$util.options(sorts)" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no games found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="No." />
				<app-table-column text="Name" />
				<app-table-column text="Version" />
				<app-table-column text="Shelf" />
				<app-table-column text="Status" />
				<app-table-column />
				<app-table-column />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.reference" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :can-empty="true" :text="item.version" />
					<app-table-cell-text :can-empty="true" :text="item.shelf" />
					<app-table-cell-text :text="$constants.games.statusText[item.status]" />
					<app-table-cell-action icon="qrcode" v-tooltip="'View QR Code'" v-on:click="onQRClick(item.id)" />
					<app-table-cell-action icon="label" v-tooltip="'View Label'" v-on:click="onBadgeClick(item.id)" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				collections: []
			},
			is: {
				syncing: false
			},
			sorts: {
				name: 'Name',
				shelf: 'Shelf',
				added: 'Most recent'
			},
			filter: {
				sort: 'name',
				collection: false
			},
			layout: '48px auto 300px 100px 100px 24px 24px 24px',
			endpoint: 'library/games',
			live: 'library/games'
		}

	},

	computed: {
		
		importexportData: function() {

			return {
				columns: {
					'reference': 'Reference',
					'game': 'BGG Game ID',
					'gameName': 'Game Name',
					'gameYear': 'Game Year',
					'version': 'BGG Version ID',
					'versionName': 'Version Name',
					'versionYear': 'Version Year',
					'shelf': 'Shelf location'
				},
				requiredColumns: ['game'],
				exportOnlyColumns: ['gameName', 'gameYear', 'versionName', 'versionYear'],
				valueMapping: {},
				params: this.getParams(),
				index: this.selectedIndex,
				endpoint: this.endpoint
			}

		},

		sheetData: function() {

			return {
				code: {
					title: 'qr code',
					endpoint: 'qr',
					index: this.selectedIndex,
					perpage: this.pagination.perpage
				},
				badge: {
					title: 'badge',
					endpoint: 'badge',
					index: this.selectedIndex,
					perpage: this.pagination.perpage
				}
			}

		}

	},

	methods: {

		onSyncClick: function() {

			this.is.syncing = true

			this.$api.post('library/sync').then(function() {

				this.load()

				this.$notify({
					message: 'Your collection has been synchronised.'
				})

				this.is.syncing = false

			}.bind(this), function() {

				this.$notify({
					type: 'error',
					message: 'Your collection failed to synchronise. Please try again or contact support.'
				})

				this.is.syncing = false

			}.bind(this))

		},

		onQRClick: function(id) {

			this.is.loading = true

			this.$api.get(['qr', id]).then(function(json) {

				this.$present(json.qr)

				this.is.loading = false

			}.bind(this))

		},

		onBadgeClick: function(id) {

			this.is.loading = true

			this.$api.get(['badge', id]).then(function(json) {

				this.$present(json.badge)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>